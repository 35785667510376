.sidebar {
  padding: 2rem;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  background-color: black;
  color: white;
  height: 100%;
  border-right: 0.25px solid var(--primary);
}

.bar-links {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  gap: 0.5rem;
}

.link.active {
  color: var(--primary);
  font-weight: bold;
}
