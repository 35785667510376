.portfolio-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.5rem;
  border-radius: 10px;
  overflow: hidden;
  background-color: black;
}

.item-header {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.75rem;
  padding: 1rem;
}

.item-h1 {
  font-size: 24px;
  font-weight: bold;
}

.proj-img {
 width: 100%;
}

.proj-img img {
  width: 100%;
  height: 100%;
}

.proj-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

@media screen and (min-width: 1000px) {
  .portfolio-item {
    flex: 1; 
    align-items: flex-start;
    justify-content: flex-start;/* Set width of each item (30% of parent width) */
    max-width: 50%;
    color: white; /* Optional: set a max-width */ /* Optional: add margin for spacing */
  }
}
