.navbar {
  /* padding: 1rem; */
}

.navbar.hidden {
  transform: translateY(-100%);
  background-color: black;
  transition: "transform 0.3s ease";
}

.navbar.visible {
  position: sticky;
  top: 0;
  transform: translateY(0);
  background-color: black;
  transition: "transform 0.3s ease";
}

.nav-content {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
}

.nav-links {
  display: none;
}
.logo-small {
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-small img {
  width: 100%;
  height: 100%;
}

.nav-btn {
  display: none;
}

@media screen and (min-width: 768px) {
  .nav-links {
    color: white;
    display: flex;
    font-size: 18px;
    gap: 1rem;
  }

  .nav-btn {
    display: block;
  }

  .menu-btn {
    display: none;
  }
}
