.tile {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
}

.tile-heading {
    display: flex;
    flex-direction: column;
}

.tile.primary {
    background-color: black;
    color: white;
}

.tile.white {
    background-color: white;
    color: black;
}
.topline {
  font-size: 1rem;
  text-transform: uppercase;
  color: var(--primary);
  font-weight: bold;
}

.headline {
  font-size: 2rem;
}

.step-content {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: .5rem;
}

.client-req  {
    display: flex;
    align-items: center;
    gap: .5rem;
    
}
.client-req span {
    font-size: .875rem;
}

.key-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .25rem;
}

.key-item span{
    font-size: .825rem;
}

.deliverables-list {
    display: flex;
    gap: 1rem;
    align-items: space-between;
    justify-content: space-between;
}