.portfolio-div {
  background-color: white;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
}

.portfolio-heading {
    font-size: 28px;
    font-weight: bold
}

.projects-mobile  {
  color: white;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.projects-wide {
    display: none; 
}

@media screen and (min-width: 1000px) {
    .projects {
        gap: 1.5rem;
    }
    .projects-wide {
        width: 100%;
        display: flex;           /* Allow wrapping of items */
        justify-content: space-between;
        gap: 2rem;
    }

    .projects-mobile {
        display: none;
    }
}